import { time } from 'virtual:build-info'

export function open(type) {
    const timestamp = new Date().getTime();
    if('development' === import.meta.env.MODE){
        // open popup with content inside from start without url.
        window.open(`/popup.html?config=${type}&time=${timestamp}`, 'popup', 'width=400,height=600');
    }
    else {
        let url = 'https://virtual-config.js.nextgen.carads.io';

        if(import.meta.env.MODE === 'preview'){
            url = 'http://' + window.location.host;
        }

        function assetsUrl(type){
            return ('assets/index-' + time + '.' + type)
        }

        const popupContent = `
                <!doctype html>
                <html lang="en">
                  <head>
                    <meta charset="UTF-8" />
                    <link rel="icon" type="image/svg+xml" href="/vite.svg" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <title>Virtual Config</title>
                  </head>
                  <body>
                    <div class="virtual-config" id="virtual-admin-popup"></div>
                    <script type="module" crossorigin src="${url}/${assetsUrl('js')}"><\/script>
                    <link rel="stylesheet" crossorigin href="${url}/${assetsUrl('css')}" />
                  </body>
                </html>
            `;

        const popupWindow = window.open("", "_blank", "width=800,height=600");
        popupWindow.document.open();
        popupWindow.document.write(popupContent);
        popupWindow.document.close();

        popupWindow.window.history.pushState(null, "", `/?config=${type}&time=${timestamp}`);
        popupWindow.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = '';
        });

        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = '';
        });
    }
}

/**
 *
 * @param {object} w
 **/
export function detect(w){
    return {
        app:            w.document.getElementById('app')??w.document.getElementById('carads_search')??null,
        slow_app:       w.document.getElementById('app-slow'),
        quick_search:   w.document.getElementById('quick_search'),
        slider:         [...w.document.querySelectorAll('.slider, .carads-slider')].filter((el) => el.dataset.id),
        total:          [...w.document.querySelectorAll('.carads-total')],
        totals:         [...w.document.querySelectorAll('.carads-totals')],
        forms:          [...w.document.querySelectorAll('.carads-f')],
        insurance:      [...w.document.querySelectorAll('.carads-insurance')],
        code:           [...w.document.querySelectorAll('.carads-code')],
        map:            w.document.getElementById('carads_map')??w.document.getElementById('carads_map_settings')??null
    }
}