<template>
  <DefineAlert>
    <p>Er du sikker på du vil lukke model</p>
    <div class="flex gap-4 justify-end">
      <BaseButton color="info" v-text="'Ok'" @click="openModals=[]" />
      <BaseButton color="warning" v-text="'cancel'" @click="openModals.pop()" />
    </div>
  </DefineAlert>
  <div class="min-w-96 relative" :class="modal ? 'min-h-screen' : 'min-h-screen'" v-if="onSamePage">
    <pre>
      {{ activeUrl }}
    </pre>
    <TopNav
        :menu="menu"
        :show="show"
        @update="(n) => goto(n)"
    />
    <MainComponent
        :current-component="currentComponent"
        :menu="menu"
        :show="show"
    />
    <ModalComponent
        :ask="ask"
        :modal="modal"
        :open-modals="openModals"
        @update:open-modals="openModals = $event"
    />
  </div>
  <div v-else>
    {{ activeUrl}} is not the same as {{ params?.list }}
  </div>
  <div v-if="reference" ref="floating" :style="floatingStyles" class="bg-black text-white text-xs px-2 py-1 rounded z-[52]">
    {{ infoMessage.message }}
  </div>
</template>
<script setup>
import {useVirtualConfig} from "./global/virtual-config.js";
import {useData, useWebsite} from "./global/website.js";
import {computed, defineAsyncComponent, onMounted, provide, ref} from "vue";
import TopNav from "@/components/TopNav.vue";
import {
  breakpointsTailwind,
  createReusableTemplate,
  useBreakpoints, useIntervalFn,
  useUrlSearchParams
} from "@vueuse/core";
import BaseButton from "@/components/BaseButton.vue";
import {flip, offset, shift, useFloating} from "@floating-ui/vue";
import ModalComponent from "./ModalComponent.vue";
import MainComponent from "./MainComponent.vue";


const reference   = ref(null);
const infoMessage = ref(null);
const floating    = ref(null);
const params      = useUrlSearchParams('history');

const [DefineAlert, ReuseAlert] = createReusableTemplate();
const {floatingStyles} = useFloating(reference, floating, {
  placement: 'top',
  middleware: [offset(10), flip(), shift()],
});

const {currentFeed, config: currentConfig}  = useVirtualConfig(params);
const {app, update, config}                         = useWebsite(currentConfig, currentFeed, params);
const {data, error, extra}                  = useData(currentFeed, params);
const openModals = ref([]);

function info(ob) {
  reference.value = ob.status ? ob.target : null;
  infoMessage.value = ob.status ? ob : null;
}

function openModal(template, data, modal = {type:null}){
  openModals.value.push({
    template,
    data,
    ...modal
  })
}


provide('currentFeed', currentFeed);
provide('config', currentConfig);
provide('api_data', {data, error, extra});

provide('modal', openModal);
provide('modal_pop', function(template, data){
  openModals.value.pop();
})
provide('tooltip', info);

const modal = computed(() => {
  // get last one or return null
  return openModals.value?.[openModals.value.length - 1] ?? null;
});

const Basic             = defineAsyncComponent(() => import('./views/search/basic.vue'));
const MapBasic          = defineAsyncComponent(() => import('./views/map/basic.vue'));
const List              = defineAsyncComponent(() => import('./views/search/list.vue'));
const Car               = defineAsyncComponent(() => import('./views/search/car.vue'));
const Output            = defineAsyncComponent(() => import('./views/output.vue'));
const QuickSearchBasic  = defineAsyncComponent(() => import('./views/quick_search/basic.vue'));
const QuickSearchList  = defineAsyncComponent(() => import('./views/quick_search/list.vue'));
const SliderBasic  = defineAsyncComponent(() => import('./views/slider/basic.vue'));
const SliderList  = defineAsyncComponent(() => import('./views/slider/list.vue'));

const breakpoints = useBreakpoints(breakpointsTailwind);

function ask() {
  // check if last item is alert if yes - do nothing
  if(openModals.value?.[openModals.value.length - 1]?.type === 'alert'){
    return;
  }


  if(breakpoints.lg.value === true && openModals.value.length <= 1) {
    openModals.value = []
    return;
  }

  openModal(ReuseAlert, {}, {type:'alert'})
}

const currentComponent = computed(() => {
  if(!app.value.type){
    return null;
  }
  switch (app.value.type) {
    case 'app':
      switch (show.value) {
        case 'basic':
          return Basic;
        case 'list':
          return List;
        case 'car':
          return Car;
        case 'output':
          return Output;
      }
      break;
    case 'quick_search':
      switch (show.value) {
        case 'basic':
          return QuickSearchBasic;
        case 'list':
          return QuickSearchList;
        case 'output':
          return Output;
      }
      break;
    case 'slider':
      switch (show.value) {
        case 'basic':
          return SliderBasic;
        case 'list':
          return SliderList;
        case 'output':
          return Output;
      }
      break;
    case 'map':
      switch (show.value) {
        case 'basic':
          return MapBasic;
        case 'output':
          return Output;
      }
      break;
    default:
      return QuickSearchBasic;
  }
});

function goto(page){
  show.value = page;
  window.scrollTo(0, 0);
}

const show = ref('basic');
const menu = computed(() => {
  return {
    map: {
      basic: {
        label:'Generelt'
      },
      output: {
        label:'Output'
      }
    },
    app:{
      basic: {
        label:'Generelt'
      },
      list: {
        label:'Lagerliste'
      },
      car: {
        label:'Bilkort'
      },
      output: {
        label:'Output'
      }
    },
    quick_search:{
      basic: {
        label:'Generelt'
      },
      list: (currentFeed.value.use_search === false ? undefined : {
        label:'Lagerliste'
      }),
      output: {
        label:'Output'
      }
    },
    slider:{
      basic: {
        label:'Generelt'
      },
      list: {
        label:'Lagerliste'
      },
      output: {
        label:'Output'
      }
    }
  }?.[app.value.type] ?? {
  };
});

const activeUrl = ref((window.opener ? window.opener.location.pathname : window.location.pathname));

const onSamePage = computed(() => {
  return params?.config === 'app' ? activeUrl.value.includes(params?.list) : true;
})

useIntervalFn(() => {
  if(activeUrl.value !== (window.opener ? window.opener.location.pathname : window.location.pathname)){
    activeUrl.value = window.opener ? window.opener.location.pathname : window.location.pathname;
  }
}, 500);

onMounted(() => {
  update();
});
</script>
